<template>
    <div>
        <h2>Disciplines</h2>
        <router-link :to="{ name: 'EditDiscipline', params: { id: -1 } }" class="button button-main">Add new discipline</router-link>
        <br />

        <label for="locale-select">Choose a language:</label>

        <b-dropdown id="locale-select" :text="selectedLocale || 'Select language'" class="m-md-2">
            <b-dropdown-item @click="setLocale('nl')">NL - Nederlands</b-dropdown-item>
            <b-dropdown-item @click="setLocale('en')">EN - English</b-dropdown-item>
        </b-dropdown>

        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Icon</th>
                    <th>Image</th>
                    <th>Questions</th>
                    <th>Levels</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="discipline in disciplines" :key="discipline.id">
                    <td :style="`background-color: ${discipline.color}`"><b>{{ discipline.id }}</b></td>
                    <td>
                        <router-link :to="{ name: 'QuestionPerDiscipline', params: { id: discipline.id } }">{{
            discipline.title }}</router-link>
                        &nbsp;<router-link :to="{ name: 'EditDiscipline', params: { id: discipline.id } }">[Edit]</router-link>
                    </td>
                    <td class="td-image">
                        <div class="tile" :style="`background-color: ${discipline.tileColor}`">
                            <img :src="discipline.imageUrl" :alt="discipline.title" width="100">
                        </div>
                    </td>
                    <td class="td-image"><img :src="fixUrl(discipline.largeImageUrl)" :alt="discipline.title"
                            width="100">
                    </td>
                    <td>{{ discipline.hasQuestions ? '✅' : '❌' }} {{ discipline.questions }}</td>
                    <td>{{ discipline.levels }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { fetchDetailedDisciplines, prefixImageUrl } from '@/repository/apiService';

export default {
    data() {
        return {
            disciplines: [],
            selectedLocale: 'nl'
        };
    },
    created() {
        this.fetchDisciplines();
    },
    methods: {
        setLocale(locale) {
            this.selectedLocale = locale;
            this.fetchDisciplines();
        },
        async fetchDisciplines() {
            try {
                this.disciplines = await fetchDetailedDisciplines(this.selectedLocale);
            } catch (error) {
                console.error('Error fetching disciplines:', error)
            }
        },
        fixUrl(url) {
            if (url && !url.startsWith('http')) {
                return prefixImageUrl(url);
            }
            return url;
        }
    }
};
</script>

<style>
.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 10pt;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.table th {
    background-color: #f4f4f4;
}

.td-image {
    background-color: #ddd;
}

.tile {
    padding: 1em;
    width: 6em;
    height: 6em;
    border-radius: 1em;
    /* background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
    display: inline-block;
}
.tile img {
    width: 100%;
    height: 100%;
}
</style>
