<template>
    <div class="container mt-3">
        <h2>Create Battle</h2>

        <form @submit.prevent="submitForm">
            <div class="form-area">
                <label for="title">Name</label>
                <input type="text" id="title" v-model="battle.title" class="form-control"
                    placeholder="Name (not visible in the app)">
            </div>

            <div class="form-area">
                <p>
                    Battle type
                </p>
                <input v-model="quiz_type" id="quiz_type_0" type="radio" value="0" name="quiz_type" /> &nbsp; <label for="quiz_type_0">Shuffle</label>
                <br />
                <input v-model="quiz_type" id="quiz_type_1" type="radio" value="1" name="quiz_type" /> &nbsp; <label for="quiz_type_1">Single discipline</label>
                <br />
                <input v-model="quiz_type" id="quiz_type_2" type="radio" value="2" name="quiz_type" /> &nbsp; <label for="quiz_type_2">Multiple disciplines</label>
                <br />
            </div>

            <div class="form-area" v-if="quiz_type==1">
                <label for="discipline-select">Discipline</label><br />
                <b-dropdown id="discipline-select" :text="selectedDiscipline || 'No discipline'" class="m-md-2">
                    <b-dropdown-item v-for="discipline in disciplines" :key="discipline.id"
                        @click="setDiscipline(discipline.id)">{{ discipline.title
                        }}</b-dropdown-item>
                </b-dropdown>                
            </div>

            <div class="form-area" v-if="quiz_type == 2">
                <div class="row">
                    <h4>Titles in the app</h4>
                    <div class="col-md-6">
                        <label for="title_nl">Titel (NL)</label>
                        <input type="text" id="title_nl" v-model="battle.title_nl" class="form-control"
                            placeholder="Titel (NL)">
                        <br />
                        <label for="descr_nl">Beschrijving (NL)</label>
                        <textarea id="descr_nl" v-model="battle.descr_nl" class="form-control"
                            placeholder="Beschrijving (NL)" rows="5"></textarea>
                    </div>                    
                    <div class="col-md-6">
                        <label for="title_en">Title (EN)</label>
                        <input type="text" id="title_en" v-model="battle.title_en" class="form-control"
                            placeholder="Title (EN)">
                        <br />
                        <label for="descr_en">Description (EN)</label>
                        <textarea id="descr_en" v-model="battle.descr_en" class="form-control"
                            placeholder="Description (EN)" rows="5"></textarea>
                    </div>
                </div>
                <div class="row mt-3">
                    <h4>Image in the app</h4>
                    <file-upload ref="upload" v-model="files" :drop="true" :drop-directory="false" @dragover.prevent
                        @drop.prevent="handleDrop" @input-file="inputFile" class="drop-area">
                        Drag your PNG file here or click to select a file.
                        <div v-if="files.length" class="file-preview">
                            <p>Selected image: {{ files[0].name }}</p>
                            <img v-if="isImage(files[0])" :src="fileUrl(files[0])" class="thumbnail" />
                        </div> 
                    </file-upload>
                </div>
                <div class="row">
                    <h4>Disciplines</h4>
                    <div v-for="discipline in disciplines" :key="discipline.id">
                        <input type="checkbox" v-model="discipline.checked" name="disciplines" :value="discipline.id" :id="`cb_discipline_${discipline.id}`" /> &nbsp; <label :for="`cb_discipline_${discipline.id}`">{{ discipline.title }} / {{ discipline.title_nl }}</label>
                    </div>
                </div>
            </div>

            <!-- <div class="form-area">
                <label for="description">Description (optional)</label>
                <textarea id="description" v-model="battle.description" class="form-control"></textarea>
            </div> -->
            <button type="submit" class="button-main" :disabled="!battle.title">Create</button>
        </form>

        <div v-if="message" :class="{ 'alert-success': success, 'alert-danger': !success }">
            {{ message }}
        </div>
    </div>

    <div class="container mt-3">
        <BattlesTableView :key="battleKey" />
    </div>
</template>

<script>
import BattlesTableView from './BattlesTableView.vue';
import { uploadBattle, fetchDetailedDisciplines } from '@/repository/apiService';
import FileUpload from 'vue-upload-component';

export default {
    components: {
        BattlesTableView,
        FileUpload
    },
    data() {
        return {
            battle: {
                title: '',
                description: '',
                title_nl: '',
                title_en: '',
                descr_nl: '',
                descr_en: '',
                disciplines: '',
                disciplineId: null,
            },
            message: '',
            success: false,
            battleKey: 0,
            selectedDiscipline: null,
            disciplines: [],
            quiz_type: 0,
            files: []
        };
    },
    async created() {
        this.fetchDisciplines();
    },
    methods: {
        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                // New file selected
            }
        },
        fileUrl(file) {
            return URL.createObjectURL(file.file);
        },
        isImage(file) {
            return file.type.startsWith('image/');
        },
        handleDrop(event) {
            event.preventDefault();
            // You can handle file data from event here if needed
            // For now, we're just preventing the default behavior
        },
        setDiscipline(disciplineId) {
            this.selectedDiscipline = this.disciplines.find(d => d.id === disciplineId).title;
            this.battle.disciplineId = disciplineId;
        },
        async fetchDisciplines() {
            try {
                const arr = await fetchDetailedDisciplines('en');
                this.disciplines = [{ title: 'No discipline', id: null }, ...arr].map((d) => {
                    return {
                        ...d,
                        checked: false
                    }
                });
                if (this.battle.disciplineId != null) {
                    this.selectedDiscipline = this.disciplines.find(d => d.id === this.battle.disciplineId).title;
                }
            } catch (error) {
                console.error('Error fetching disciplines:', error)
            }
        },
        async submitForm() {
            if (!this.battle.title) return;

            try {
                // Get value of radio quiz_type
                this.battle.quiz_type = this.quiz_type;

                if (this.quiz_type == 2) {
                    this.battle.disciplines = this.disciplines
                        .filter(d => d.checked)
                        .map(d => d.id)
                        .join(',');
                }

                const formData = new FormData();
                if (this.files && this.quiz_type == 2) {
                    formData.append('imageFile', this.files[0].file);
                }
                formData.append('battle', JSON.stringify(this.battle));
                const response = await uploadBattle(formData);
                
                this.message = response.success ? 'Battle created!' : 'Error: ' + response.message;
                this.success = response.success;
                if (this.success) {
                    this.battle = {
                        title: '',
                        description: '',
                        title_nl: '',
                        title_en: '',
                        descr_nl: '',
                        descr_en: '',
                        disciplines: '',
                        disciplineId: null,
                    };
                    this.selectedDiscipline = null;
                    this.selected = '';
                    this.battleKey = Math.random();
                    this.files = [];
                }
            } catch (error) {
                console.log(error);
                this.message = error.message;
                this.success = false;
            }
        }
    }
};
</script>

<style>
.form-area {
    background: #f0f0f0;
    padding: 1em;
    margin-bottom: 2em;
    border-radius: 0.5em;
}

.alert-danger,
.alert-success {
    padding: 1rem;
    border-radius: 6px;
}
#cb_discipline_null, label[for="cb_discipline_null"] {
    display: none;
}
</style>
