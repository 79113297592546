<template>
    <h2>{{ disciplineId == -1 ? 'Add' : 'Edit' }} Discipline</h2>

    <form @submit.prevent="submitForm">

        <div class="form-area">
            <h4>Titles</h4>
            <div class="row">
                <div class="col-md-6">
                    <label for="titleNL">Titel (NL)</label>
                    <input type="text" id="titleNL" v-model="discipline.title_nl" class="form-control" placeholder="Lange titel (NL)">
                    <br />
                    <label for="shortTitleNL">Korte titel (NL)</label>
                    <input type="text" id="shortTitleNL" v-model="discipline.shortTitle_nl" class="form-control" placeholder="Korte titel (NL)">
                </div>
                <div class="col-md-5">
                    <label for="titleEN">Title (EN)</label>
                    <input type="text" id="titleEN" v-model="discipline.title_en" class="form-control" placeholder="Long title (EN)">
                    <br />
                    <label for="shortTitleEN">Short title (EN)</label>
                    <input type="text" id="shortTitleEN" v-model="discipline.shortTitle_en" class="form-control" placeholder="Short title (EN)">
                </div>
            </div>
        </div>

        <div class="form-area">
            <h4>Styling</h4>
            <label for="sortOrder">Sort order</label>
            <input type="text" id="sortOrder" v-model="discipline.order" class="form-control-small"
                placeholder="Sort order (number)">
                <br />
            <label for="color">Color</label>
            <input type="text" id="color" v-model="discipline.color" class="form-control-small"
                placeholder="Color (#rrggbb)">
            <br />
            <label for="tileColor">Tile background color</label>
            <input type="text" id="tileColor" v-model="discipline.tileColor" class="form-control-small"
                placeholder="Tile color (#rrggbb)">
        </div>

        <div class="form-area">
            <h4>Access and visibility</h4>
            <input type="checkbox" id="premium" v-model="discipline.premium" :true-value="true" :false-value="false">
            <label for="premium">Subscribers only</label>
            <br />
            <input type="checkbox" id="requiresLogin" v-model="discipline.requiresLogin" :true-value="true" :false-value="false">
            <label for="requiresLogin">Logged in users only</label>
            <br />
            <input type="checkbox" id="isFree" v-model="discipline.isFree" :true-value="true" :false-value="false" :disabled="discipline.premium">
            <label for="isFree">Free to play (no login required after 3 lessons)</label>
            <br />
            <input type="checkbox" id="showLocks" v-model="discipline.showLocks" :true-value="true" :false-value="false" :disabled="!discipline.isFree">
            <label for="showLocks">Show lock icons</label>
            <br />
            <input type="checkbox" id="showChecks" v-model="discipline.showChecks" :true-value="true" :false-value="false" :disabled="!discipline.isFree">
            <label for="showChecks">Show checkmark icons</label>
            <br />
            <input type="checkbox" id="isVisible" v-model="discipline.isVisible" :true-value="true" :false-value="false">
            <label for="isVisible">Show in the app.</label> <b>Notice: disciplines are hidden by default. Check this box to make them show up in the app. Disciplines without questions are never shown.</b>
        </div>

        <div class="form-area" v-if="disciplineId != -1">
            <h4>Images</h4>
            <div class="row">
                <div class="col-md-6">
                    <label>Tile icon (96&times;96 pixels)</label>
                    <br />
                    <div class="tile" :style="`background-color: ${discipline.tileColor}`">
                        <img :src="fixImageUrl(discipline.imageUrl)" v-if="discipline.imageUrl" />
                    </div>
                    <br />
                    <router-link :to="{ name: 'EditDisciplineImage', params: { id: discipline.id, isIcon: 1 } }" class="button button-main">Edit icon</router-link>
                </div>
                <div class="col-md-5">
                    <label>Large image</label>
                    <br />
                    <div class="preview" :style="`background-color: ${discipline.color}`">
                        <img :src="fixImageUrl(discipline.largeImageUrl)" v-if="discipline.largeImageUrl" />
                    </div>
                    <br />
                    <router-link :to="{ name: 'EditDisciplineImage', params: { id: discipline.id, isIcon: 0 } }" class="button button-main">Edit image</router-link>
                </div>
            </div>            
        </div>

        <button type="submit" class="button-main button-big">Save</button>

        <div v-if="message" :class="{ 'alert-success': success, 'alert-danger': !success }">
            {{ message }}
        </div>
    </form>
</template>

<script>
import { createDiscipline, fetchDiscipline, prefixImageUrl } from '@/repository/apiService';

export default {
    data() {
        return {
            disciplineId: -1,
            discipline: {
                id: -1,
                title_nl: '',
                shortTitle_nl: '',
                title_en: '',
                shortTitle_en: '',
                order: '',
                color: '#ffffff',
                tileColor: '#ffffff',
                premium: false,
                isFree: false,
                showLocks: true,
                showChecks: true,
                requiresLogin: false,
                nudgeLogin: false,
                firebaseKey: null,
                imageUrl: null,
                largeImageUrl: null
            },
            files: [],
            largeFiles: [],
            message: '',
            success: false
        };
    },
    created() {
        this.refresh();
    },
    watch: {
        '$route.params.id': {
            immediate: true,
            handler(newVal) {
                this.disciplineId = newVal;
                this.refresh();
            }
        }
    },
    methods: {
        fixImageUrl(url) {
            return url.startsWith('http') ? url : prefixImageUrl(url);
        },
        async refresh() {
            try {
                if (this.disciplineId == -1) return;
                this.discipline = await fetchDiscipline(this.disciplineId);
            } catch (error) {
                // Handle errors, e.g., show an error message
                console.log(error);
                this.message = error.message;
                this.success = false;
            }
        },
        async submitForm() {
            try {
                const response = await createDiscipline(this.discipline);
                if (this.disciplineId == -1) {
                    this.message = response.success ? 'Discipline created!' : 'Error: ' + response.message;
                } else {
                    this.message = response.success ? 'Discipline updated!' : 'Error: ' + response.message;
                }
                this.success = response.success;
                if (this.success) {
                    window.setTimeout(
                        () => {
                            // Navigate back to form:
                            location.href = `/`;
                        },
                        500
                    );
                }
            } catch (error) {
                console.log(error);
                this.message = error.message;
                this.success = false;
            }
        }
    }
}
</script>

<style scoped>
input[type="checkbox"] {
    width: 3em;
}
.form-area {
    background: #f0f0f0;
    padding: 1em;
    margin-bottom: 2em;
    border-radius: 0.5em;
}
.form-control-small {
    display: block;
    width: 10em;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.tile {
    padding: 1em;
    width: 6em;
    height: 6em;
    border-radius: 1em;
    /* background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
    display: inline-block;
}
.preview {
    width: 12em;
    height: 22em;
    border-radius: 0.5em;
    display: inline-block;
    overflow: hidden;
}
.tile img {
    width: 100%;
    height: 100%;
}
.preview img {
    width: 100%;
    margin-top: 2em;
}
.button-big {
    transform: scale(1.5) translateX(10px);
    margin-bottom: 2em;
}
.alert-success, .alert-danger {
    display: inline-block;
    transform: translateX(30px);
}
</style>
