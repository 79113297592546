<template>
    <h2>{{ discipline.title_nl }}</h2>

    <form @submit.prevent="submitForm">

        <div class="form-area">
            <h4 v-if="isIcon == 1">Upload tile icon (96&times;96 pixels, PNG)</h4>
            <h4 v-else>Upload large image (PNG, max. 5 Mb)</h4>
            <br />
            <!-- <div class="tile" :style="`background-color: ${discipline.tileColor}`">
                <img :src="fixImageUrl(discipline.imageUrl)" v-if="discipline.imageUrl" />
            </div> -->
            <file-upload ref="upload" v-model="files" :drop="true" :drop-directory="false" @dragover.prevent
                @drop.prevent="handleDrop" @input-file="inputFile" class="drop-area">
                Drag your PNG file here or click to select a file.
                <div v-if="files.length" class="file-preview">
                    <p>Selected image: {{ files[0].name }}</p>
                    <img v-if="isImage(files[0])" :src="fileUrl(files[0])" class="thumbnail" />
                </div> 
            </file-upload>
            <br />
            <button type="submit" class="button-main">Save</button>
        </div>
    </form>

    <div v-if="message" :class="{ 'alert-success': success, 'alert-danger': !success }">
        {{ message }}
    </div>
</template>

<script>
import { setDisciplineImage, fetchDiscipline, prefixImageUrl } from '@/repository/apiService';
import FileUpload from 'vue-upload-component';

export default {
    components: {
        FileUpload
    },
    data() {
        return {
            disciplineId: -1,
            discipline: {
                title_nl: '',
                shortTitle_nl: '',
                title_en: '',
                shortTitle_en: '',
                order: '',
                color: '#ffffff',
                tileColor: '#ffffff',
                premium: false,
                isFree: false,
                nudgeLogin: false,
                firebaseKey: null,
                imageUrl: null,
                largeImageUrl: null
            },
            files: [],
            isIcon: 1,
            message: '',
            success: false
        };
    },
    created() {
        this.refresh();
    },
    watch: {
        '$route.params.id': {
            immediate: true,
            handler(newVal) {
                this.disciplineId = newVal;
                this.refresh();
            }
        },
        '$route.params.isIcon': {
            immediate: true,
            handler(newVal) {
                this.isIcon = newVal;
                console.log(`isIcon: ${newVal}`);
            }
        }
    },
    methods: {
        inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                // New file selected
            }
        },
        fileUrl(file) {
            return URL.createObjectURL(file.file);
        },
        isImage(file) {
            return file.type.startsWith('image/');
        },
        handleDrop(event) {
            event.preventDefault();
            // You can handle file data from event here if needed
            // For now, we're just preventing the default behavior
        },
        fixImageUrl(url) {
            return url.startsWith('http') ? url : prefixImageUrl(url);
        },
        async refresh() {
            try {
                if (this.disciplineId == -1) return;
                this.discipline = await fetchDiscipline(this.disciplineId);
                // this.title_nl = discipline.title_nl;
                // this.shortTitle_nl = discipline.shortTitle_nl;
                // this.title_en = discipline.title_en;
                // this.shortTitle_en = discipline.shortTitle_en;
                // this.order = discipline.order;
                // this.color = discipline.color;
                // this.tileColor = discipline.tileColor;
                // this.premium = discipline.premium;
                // this.isFree = discipline.isFree;
                // this.nudgeLogin = discipline.nudgeLogin;
                // this.firebaseKey = discipline.firebaseKey;
            } catch (error) {
                // Handle errors, e.g., show an error message
                console.log(error);
                this.message = error.message;
                this.success = false;
            }
        },
        async submitForm() {
            try {
                const formData = new FormData();
                formData.append('imageFile', this.files[0].file);
                formData.append('isIcon', this.isIcon);
                formData.append('disciplineId', this.disciplineId);

                const response = await setDisciplineImage(formData);
                this.message = response.success ? 'Image added!' : 'Error: ' + response.message;
                this.success = response.success;
                if (this.success) {
                    window.setTimeout(
                        () => {
                            // Navigate back to form:
                            location.href = `/discipline/${this.disciplineId}`;
                        },
                        500
                    );
                }
            } catch (error) {
                console.log(error);
                this.message = error.message;
                this.success = false;
            }
        }
    }
}
</script>

<style scoped>
input[type="checkbox"] {
    width: 3em;
}
.form-area {
    background: #f0f0f0;
    padding: 1em;
    margin-bottom: 2em;
    border-radius: 0.5em;
}
.form-control-small {
    display: block;
    width: 10em;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.tile {
    padding: 1em;
    width: 6em;
    height: 6em;
    border-radius: 1em;
    /* background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
    display: inline-block;
}
.preview {
    width: 12em;
    height: 22em;
    border-radius: 0.5em;
    display: inline-block;
}
.tile img {
    width: 100%;
    height: 100%;
}
.preview img {
    width: 100%;
    margin-top: 2em;
}
</style>
